<template>
  <div class="">
    <button
      v-if="url"
      type="button"
      class="btn"
      :class="[buttonType, buttonColor]"
      @click="goTo(url)"
      :disabled="isDisabled === true"
    >
      <slot></slot>
    </button>

    <button
      v-else
      type="button"
      class="btn"
      :class="[buttonType, buttonColor]"
      :disabled="isDisabled === true"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "CaButton",
  props: {
    type: {
      type: String,
      default: "filled",
    },
    url: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonType() {
      return "btn--" + this.type;
    },
    buttonColor() {
      return "btn--" + this.type + "--" + this.color;
    },
  },
  methods: {
    goTo: function (url) {
      if (this.url !== null) {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped></style>
