<template>
  <div class="full-page-content-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FullPageContentContainer",
};
</script>

<style scoped>
.full-page-content-container {
  padding: 3rem 1.5rem;
  background-color: #ffffff;
  /* width: 100%; */
  width: 100%;
  /* overflow: auto; */
  margin: 0 auto;
}
</style>
