<template>
  <div class="page-header">
    <div class="page-header__main">
      <h2 class="page-header__title">{{ title }}</h2>
      <div class="page-header__sub" v-if="subHeading">
        <p>
          ( {{ subHeader }} /
          <router-link :to="linkUrl" class="page-header__link">
            {{ linkName }}</router-link
          >
          )
        </p>
      </div>
    </div>
    <div class="page-header__extra">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    subHeading: {
      type: Boolean,
      default: false,
    },
    subHeader: {
      type: String,
      required: true,
    },
    linkName: {
      type: String,
    },
    linkUrl: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
